$(function () {
  /* Set CSRF-Token for ajax */
  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  /*
   * jquery.cookie settings
   */
  $.cookie.defaults.path = "/";
});
