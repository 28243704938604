import { createApp, h } from "vue";
import store from "@js/store";

const mount = (components, mountsStore = false) => {
  document.addEventListener("DOMContentLoaded", () => {
    let templates = document.querySelectorAll("[data-vue]");

    for (let el of templates) {
      const data = {
        render() {
          return h(components[el.dataset.vue], { ...el.dataset });
        },
      };

      let app = createApp(data);
      if (mountsStore) app.use(store);
      app.mount(el);
    }
  });
};

export { mount };
