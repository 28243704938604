const namespaced = true;

const state = {
  mounted: [],
  mermaid: "flowchart TD",
};

const mutations = {
  setMounted(state, chassisId) {
    if (state.mounted.indexOf(chassisId) === -1) {
      state.mounted.push(chassisId);
    }
  },
  setMermaid(state, text) {
    state.mermaid = text;
  },
};

export default {
  namespaced,
  state,
  mutations,
};
