$(function () {
  $(".select2-tags").select2({
    tags: true,
    theme: "bootstrap-5",
  });

  $(".select2-js").select2({
    theme: "bootstrap-5",
  });
});
