import { createStore } from "vuex";

// module
import equipmentForm from "@js/store/modules/equipmentForm.js";
import portForm from "@js/store/modules/portForm.js";
import l1ConnectorTraceModal from "@js/store/modules/l1ConnectorTraceModal.js";

const store = createStore({
  modules: {
    equipmentForm,
    portForm,
    l1ConnectorTraceModal,
  },
});

export default store;
