import client from "./client";

const resource = "/tickets/count.json";

export default {
  tickets_count: async function () {
    let data = null;

    data = await client
      .get(resource)
      .then((response) => {
        return response.data.tickets;
      })
      .catch((error) => {
        console.log(error);
      });

    return data;
  },
};
