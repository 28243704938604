const namespaced = true;

const list = [
  { type: "800G", category: "ehg" },
  { type: "400G", category: "fhg" },
  { type: "100G", category: "hg" },
  { type: "50G", category: "50g" },
  { type: "40G", category: "fg" },
  { type: "25G", category: "25g" },
  { type: "10G", category: "xg" },
  { type: "2.5G", category: "mg" },
  { type: "1000BASE", category: "ge" },
  { type: "100BASE", category: "fe" },
  { type: "SDI", category: "sdi" },
  { type: "Serial", category: "con" },
];

const state = {
  mediaType: "",
  mediaCategory: "",
};

const getters = {
  mediaCategory: (state) => {
    if (state.mediaType === "") {
      return "";
    }

    list.forEach(function (item) {
      if (state.mediaType.indexOf(item["type"]) !== -1) {
        state.mediaCategory = item["category"];
      }
    });

    return state.mediaCategory;
  },
};

const mutations = {
  setMediaType(state, mediaType) {
    state.mediaType = mediaType;

    if (state.mediaCategory === null || state.mediaCategory === "") {
      state.mediaCategory = getters.mediaCategory(state);
    }
  },
  setMediaCategory(state, mediaCategory) {
    state.mediaCategory = mediaCategory;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
