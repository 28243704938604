const namespaced = true;

const state = {
  deviceId: "",
  loopback4: "",
  loopback6: "",
  management4: "",
};

const getters = {
  loopback4: (state) => {
    if (state.deviceId === "") {
      return "";
    }

    return "45.0.0." + state.deviceId;
  },
  loopback6: (state) => {
    if (state.deviceId === "") {
      return "";
    }

    return "2001:3e8::" + state.deviceId;
  },
  management4: (state) => {
    if (state.deviceId === "") {
      return "";
    }

    return "172.16.0." + state.deviceId;
  },
};

const mutations = {
  setDeviceId(state, deviceId) {
    state.deviceId = deviceId;

    if (state.loopback4 === "" && state.loopback6 === "") {
      state.loopback4 = getters.loopback4(state);
      state.loopback6 = getters.loopback6(state);
    }

    if (state.management4 === "") {
      state.management4 = getters.management4(state);
    }
  },
  setLoopback4(state, loopback4) {
    state.loopback4 = loopback4;
  },
  setLoopback6(state, loopback6) {
    state.loopback6 = loopback6;
  },
  setManagement4(state, management4) {
    state.management4 = management4;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
