<template lang="pug">
.row.text-center
  .col-6
    a.text-primary(v-bind:href="openTicketsPath")
      span.ticket-count {{openCount}}
      .small オープン
  .col-6
    a.text-danger(v-bind:href="assignedTicketsPath")
      span.ticket-count {{assignedCount}}
      .small 割り当て
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import TicketsCountApi from "@js/api/tickets_count";

const fetchInterval = 3 * 60 * 1000;

const props = defineProps({
  ticketsCount: { type: String, default: null },
  openTicketsPath: { type: String, default: null },
  assignedTicketsPath: { type: String, default: null },
});

const countData = ref({ all: 0, open: 0, assigned: 0 });

// eslint-disable-next-line no-unused-vars
const openCount = computed(() => countData.value["open"]);
// eslint-disable-next-line no-unused-vars
const assignedCount = computed(() => countData.value["assigned"]);

onMounted(() => {
  // set init value
  let tickets_count = JSON.parse(props.ticketsCount);

  countData.value["open"] = tickets_count["open"];
  countData.value["assigned"] = tickets_count["assigned"];

  // set timer
  setInterval(
    function () {
      fetch();
    }.bind(this),
    fetchInterval,
  );
});

async function fetch() {
  const data = await TicketsCountApi.tickets_count();

  if (data != null) {
    countData.value = data;
  }
}
</script>

<style scoped>
.ticket-count {
  font-size: 2em;
}
</style>
