// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
//import * as ActiveStorage from "@rails/activestorage";
//import "channels";

Rails.start();
//ActiveStorage.start();

// lib
import "jquery.observe_field";
import "jquery.cookie";
import "tablesorter";

import "select2";
import "./select2";

import "./vendor/jquery.ex-table-filter";

import "./vendor/jquery.multiselectbox";

// bootstrap
import "bootstrap";

// fortawesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

// special entry point of application.css
import "@css/application";

// dark mode
import "@js/switch-darkmode";
